import React, { useEffect } from "react";

export default function Projects() {

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {

            if(entry.isIntersecting) {
                entry.target.classList.add('title-animation');
                return;
            }

        })
    
    })

    useEffect(() => {
        observer.observe(document.querySelector('.c-title1'))
    })


    return (
        <>
            <div className="mt-5 text-center">
                <span id="projects" className="mb-32"></span>
                <h3 className="text-4xl mx-auto mb-1 font-bold mt-5 c-title1">PROJECTS</h3>
                <hr className="border-4 bg-neutral-800 mx-auto w-32 mb-5"></hr>
                <div className="flex justify-center text-center flex-wrap">
                        <div className="card bg-slate-100 border-none rounded-none m-2" style={{width: 35 + "rem"}}>
                        <img src="thinkful-bnb.png" className="card-img-top rounded-none" alt="..."/>
                        <div className="card-body">
                            <h5 className="card-title font-bold">ThinkfulBnB</h5>
                            <p className="card-text">A mobile-first developed, responsive website for booking BnB's and finding travel destinations.</p>
                            <a href="https://watermana.github.io/thinkfulbnb-assessment/" target={"_blank"} className="btn bg-cyan-600 text-white rounded-none hover:bg-cyan-600">View Live</a>
                        </div>
                        </div>
                        <div className="card bg-slate-100 border-none rounded-none m-2" style={{width: 35 + "rem"}}>
                        <img src="movies.png" className="card-img-top rounded-none" alt="..."/>
                        <div className="card-body ">
                            <h5 className="card-title font-bold">We Love Movies</h5>
                            <p className="card-text">A full-stack react/express application to find the latest showing in the theater of your choice.</p>
                            <a href="https://lit-peak-99381.herokuapp.com/" target={"_blank"} className="btn bg-cyan-600 hover:bg-cyan-600 text-white rounded-none">View Live</a>
                        </div>
                        </div>
                        <div className="card bg-slate-100 border-none rounded-none m-2" style={{width: 35 + "rem"}}>
                        <img src="reservation.png" className="card-img-top rounded-none" alt="..."/>
                        <div className="card-body">
                            <h5 className="card-title font-bold">Periodic Tables</h5>
                            <p className="card-text">A full-stack react/express application for restaurant hosts to manage reservations.</p>
                            <a href="https://aw-reservations-front.herokuapp.com/dashboard" target={"_blank"} className="btn bg-cyan-600 hover:bg-cyan-600 text-white rounded-none">View Live</a>
                        </div>
                        </div>
                        <div className="card bg-slate-100 border-none rounded-none m-2" style={{width: 35 + "rem"}}>
                        <img src="3-a-day.png" className="card-img-top rounded-none" alt="..."/>
                        <div className="card-body">
                            <h5 className="card-title font-bold">3 A Day</h5>
                            <p className="card-text">3 A Day is a basic 'to-do' app that uses several API's to collect local weather data, background images, and daily inspirational quotes.</p>
                            <a href="https://three-a-day.herokuapp.com/" target={"_blank"} className="btn bg-cyan-600 hover:bg-cyan-600 text-white rounded-none">View Live</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}