import React, { useEffect } from "react";

export default function Skils() {

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {

            if(entry.isIntersecting) {
                let child = entry.target.children;
                for(let i = 0; i < child.length; i++) {
                    let skill = child[i].children[1].children[0];
                    skill.classList.add("skills-animation")
                }

                return;
            }

        })
    
    })

    useEffect(() => {
        observer.observe(document.querySelector('.skill-section'))
    })

    return (
        <div className="flex-1">
        <div className="flex flex-col skill-section">
        <div className="flex justify-center lg:justify-start">
                <div className="h-9 w-3/12 lg:w-2/12 bg-cyan-600 my-3 ml-3">
                    <p className="text-center text-white font-bold">HTML</p>
                    </div>
                <div className="flex justify-between items-center h-9 w-6/10 bg-slate-300 my-3">
                    <div className="h-9 w-9/10 bg-cyan-500"></div>
                    <span className="font-bold">90%</span>
                </div>
            </div>
            <div className="flex justify-center lg:justify-start">
                <div className="h-9 w-3/12 lg:w-2/12 bg-cyan-600 my-3 ml-3">
                    <p className="text-center text-white font-bold">CSS</p>
                    </div>
                <div className="flex justify-between items-center h-9 w-6/10 bg-slate-300 my-3">
                    <div className="h-9 w-9/10 bg-cyan-500"></div>
                    <span className="font-bold">90%</span>
                </div>
            </div>
            <div className="flex justify-center lg:justify-start">
                <div className="h-9 w-3/12 lg:w-2/12 bg-cyan-600 my-3 ml-3">
                    <p className="text-center text-white font-bold">Bootstrap</p>
                    </div>
                <div className="flex justify-between items-center h-9 w-6/10 bg-slate-300 my-3">
                    <div className="h-9 w-7/10 bg-cyan-500"></div>
                    <span className="font-bold">70%</span>
                </div>
            </div>
            <div className="flex justify-center lg:justify-start">
                <div className="h-9 w-3/12 lg:w-2/12 bg-cyan-600 my-3 ml-3">
                    <p className="text-center text-white font-bold">JavaScript</p>
                    </div>
                <div className="flex justify-between items-center h-9 w-6/10 bg-slate-300 my-3">
                    <div className="h-9 w-8/10 bg-cyan-500"></div>
                    <span className="font-bold">80%</span>
                </div>
            </div>
            <div className="flex justify-center lg:justify-start">
                <div className="h-9 w-3/12 lg:w-2/12 bg-cyan-600 my-3 ml-3">
                    <p className="text-center text-white font-bold">React</p>
                    </div>
                <div className="flex justify-between items-center h-9 w-6/10 bg-slate-300 my-3">
                    <div className="h-9 w-7/10 bg-cyan-500"></div>
                    <span className="font-bold">70%</span>
                </div>
            </div>
            <div className="flex justify-center lg:justify-start">
                <div className="h-9 w-3/12 lg:w-2/12 bg-cyan-600 my-3 ml-3">
                    <p className="text-center text-white font-bold">Express</p>
                    </div>
                <div className="flex justify-between items-center h-9 w-6/10 bg-slate-300 my-3">
                    <div className="h-9 w-6/10 bg-cyan-500"></div>
                    <span className="font-bold">60%</span>
                </div>
            </div>
            <div className="flex justify-center lg:justify-start">
                <div className="h-9 w-3/12 lg:w-2/12 bg-cyan-600 my-3 ml-3">
                    <p className="text-center text-white font-bold">PostgreSQL</p>
                    </div>
                <div className="flex justify-between items-center h-9 w-6/10 bg-slate-300 my-3">
                    <div className="h-9 w-4/10 bg-cyan-500"></div>
                    <span className="font-bold">40%</span>
                </div>
            </div>
        </div>
        </div>
    )
}