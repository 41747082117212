import React from "react";
import 'animate.css';
export default function Hero() {

    function exploreHandler() {
        document.getElementById('about-me').scrollIntoView({behavior: 'smooth'});
    }


    return(

        <div className="hero ">
            
            <div className="relative text-center">
                
                <h2 className="font-sans text-white text-7xl animate__animated animate__fadeInDown animate__slow select-none">Hi, I'm <span className="text-cyan-800">Austin Waterman</span>,</h2>
                <h2 className="font-sans text-white text-7xl animate__animated animate__fadeInDown animate__slow select-none mb-2"> Front End Web Developer</h2>
                <button onClick={exploreHandler} className=" c-btn animate__animated animate__fadeIn animate__slower border-2 p-1 pr-2 pl-2 text-xl text-white">Explore<p className="text-2xl c-mdi inline-block align-top"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
</svg></p></button>
            </div>
        </div>

    )
}