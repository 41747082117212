import React, {useState, useEffect} from "react";
import { useForm, ValidationError, validationError} from "@formspree/react";
import Bruh from './images/bruh.svg';
import gitHub from './images/github.svg';
import linkedIn from './images/linkedin.svg';
import envelope from './images/envelope-fill.svg';


export default function Contact() {


    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {

            if(entry.isIntersecting) {
                entry.target.classList.add('title-animation');
                return;
            }

        })
    
    })



    useEffect(() => {
        observer.observe(document.querySelector('.c-title2'))
    })

    const [state, handleSubmit] = useForm("mbjwnblw");
    if (state.succeeded) {
        
        return (
            <>
        <img className="pointer-events-none" src={Bruh} />
        <form onSubmit={handleSubmit} className="flex flex-col justify-center text-center bg-neutral-800 pb-4">
        <h2 id="contact" className="text-white text-4xl mb-1 font-bold c-title2">CONTACT</h2>
        <hr className="border-4 border-slate-50 mx-auto w-32 mb-5"></hr>
    
            <input  name="name"  placeholder="Name" className="bg-neutral-700 text-white mx-auto m-2"></input>
            
            <input  name="email"  type="email" placeholder="Email address" className="bg-neutral-700 text-white mx-auto m-2"></input>
            <ValidationError prefix="Email" field="email" errors={state.errors} />
            <textarea  name="message"  placeholder="Your message" className="bg-neutral-700 text-white mx-auto m-2 h-32"></textarea>
            <ValidationError prefix="Message" field="message" errors={state.errors} />
            <p className="text-lime-600">Your message has been recieved!</p>
            <button className="text-white bg-cc-magenta hover:bg-pink-700 w-20 mx-auto mt-1" type="submit" disabled={state.submitting}>Submit</button>
        </form>
        <footer className="bg-neutral-900 flex flex-col align-items-center">
            
            <ul className="flex flex-row align-items-center">
                <li>
                    <a className="m-5" href="https://github.com/Watermana"> <img src={gitHub} /> </a>
                </li>
                <li>
                   <a className="m-5" href="https://www.linkedin.com/in/austin-waterman/"> <img src={linkedIn} /> </a>
                </li>
                <li>
                   <a className="m-5" href="mailTo:austin.d.waterman@gmail.com"> <img src={envelope} /> </a>
                </li>
            </ul>

            <p className="text-white p-2">Austin Waterman &#169;2022</p>

        </footer>
        </>
        )
    }


    return (
        <>
        <img id="contact" className="pointer-events-none" src={Bruh} />
        <form onSubmit={handleSubmit} className="flex flex-col justify-center text-center bg-neutral-800 pb-4">
        <h2 id="contact" className="text-white text-4xl mb-1 font-bold c-title2">CONTACT</h2>
        <hr className="border-4 border-slate-50 mx-auto w-32 mb-5"></hr>
    
            <input  name="name"  placeholder="Name" className="bg-neutral-700 text-white mx-auto m-2"></input>
            
            <input  name="email"  type="email" placeholder="Email address" className="bg-neutral-700 text-white mx-auto m-2"></input>
            <ValidationError prefix="Email" field="email" errors={state.errors} />
            <textarea  name="message"  placeholder="Your message" className="bg-neutral-700 text-white mx-auto m-2 h-32"></textarea>
            <ValidationError prefix="Message" field="message" errors={state.errors} />
            <button className="text-white bg-cc-magenta hover:bg-pink-700 w-20 mx-auto mt-1" type="submit" disabled={state.submitting}>Submit</button>
        </form>
        <footer className="bg-neutral-900">
            
            <ul className="flex align-items-center justify-evenly lg:w-1/5 mx-auto pt-4">
                <li>
                    <a className="" href="https://github.com/Watermana"> <img src={gitHub} /> </a>
                </li>
                <li>
                   <a className="" href="https://www.linkedin.com/in/austin-waterman/"> <img src={linkedIn} /> </a>
                </li>
                <li>
                   <a className="" href="mailTo:austin.d.waterman@gmail.com"> <img src={envelope} /> </a>
                </li>
            </ul>

            <p className="text-white pt-3 pb-5 text-center">Austin Waterman &#169;2022</p>

        </footer>
        </>
    )
}