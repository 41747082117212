import React from 'react';
import AboutMe from './Aboutme';
import './App.css';
import Contact from './Contact';
import Hero from './hero';
import Navbar from './Navbar';
import Projects from './Projects';

function App() {

  return (
    <main className="">

      <Hero />
      <Navbar />
      <AboutMe />
      <Projects />
      <Contact />

    </main>
  );
}

export default App;
