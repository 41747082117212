import React from "react";
import hamburger from "./images/list.svg";
export default function Navbar() {

  function navHandler(elementId) {
    document.getElementById(elementId).scrollIntoView({behavior: 'smooth'});
}



    return (
<nav className="navbar navbar-expand-lg bg-neutral-800 dark:bg-neutral-700 z-10">
  <div className="container-fluid">
    <a className="navbar-brand" onClick={() => window.scrollTo(0, 0)}><p className="pointer-events-none text-white">Home</p></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span><img src={hamburger}/></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div className="navbar-nav">
      <a className="nav-link text-white" aria-current="page" onClick={() => navHandler("about-me")}><p className="pointer-events-none">About Me</p></a>
        <a className="nav-link text-white" onClick={() => navHandler("about-me")}><p className="pointer-events-none">Skills</p></a>
        <a className="nav-link text-white" onClick={() => navHandler("projects")}><p className="pointer-events-none">Projects</p></a>
        <a className="nav-link text-white" onClick={() => navHandler("contact")}><p className="pointer-events-none">Contact</p></a>
      </div>
    </div>
  </div>
</nav>
    )
}