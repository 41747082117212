import React, { useEffect } from "react";
import 'animate.css';
import Skils from "./Skills";

export default function AboutMe() {

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {

            if(entry.isIntersecting) {
                entry.target.classList.add('title-animation');
                return;
            }

        })
    
    })

    useEffect(() => {
        observer.observe(document.querySelector('.c-title'))
    })


    return (
        <>
        <span id="about-me"></span>
        <h2 className="text-black dark:text-white text-4xl font-bold text-center mt-5 c-title">
        ABOUT
    </h2>
    <hr className="border-4 bg-neutral-800 mx-auto w-24 mb-5"></hr>
        <div className="flex flex-col lg:flex-row">

            <div className="text-center flex-1">
                <img src="project-images/portrait.png" className="w-80 mx-auto" alt="portrait" />


                <p className="text-black dark:text-white mx-auto lg:w-4/6 text-sm lg:text-base">
                    My name is Austin Waterman, I'm an entry level front end developer looking to find my first role.
                    I am passionate about creating engaging user experiences with animated and responsive websites. I bring 6 months
                    of experience from Thinkful's Software Engineering Program and I expect to complete my B.S. degree in 
                    Computer Science from WGU in May 2024. Let's build something great together!
                </p>
            </div>
        <Skils />
        </div>
        </>
    )
}